import { Grid } from "@mui/material";
import { ISelectionList } from "shared/components/selectionComponent/models/selectionList";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";

export interface PositionCategoryItemConfigItemComponentProps {
    articleTypeSelection: ISelectionList;
    filterSelectedArticleTypes: (searchText: string) => void;
    filterUnselectedArticleTypes: (searchText: string) => void;   
    selectArticleTypes: (ids: number[]) => void;
    unselectArticleTypes: (ids: number[]) => void;     
    positionSelection: ISelectionList;
    filterSelectedPositions: (searchText: string) => void;
    filterUnselectedPositions: (searchText: string) => void;   
    selectPositions: (ids: number[]) => void;
    unselectPositions: (ids: number[]) => void;  
}

export const PositionCategoryItemConfigItemComponent = (props: PositionCategoryItemConfigItemComponentProps) => {
    return (
        <>
        <Grid container spacing={2} marginBottom={"20px"}>
            <Grid item md={12}>
                <SelectionListComponent
                    entityName='Artikeltyp'
                    title='Artikeltypen'
                    onFilterSelected={props.filterSelectedArticleTypes}
                    onFilterUnselected={props.filterUnselectedArticleTypes}
                    selectEntities={props.selectArticleTypes}
                    unselectEntities={props.unselectArticleTypes}
                    showSelectedAllIcon={true}
                    selectionList={props.articleTypeSelection} />
            </Grid>
            <Grid item md={12}>
                <SelectionListComponent
                    entityName='Positionen'
                    title='Positionen'
                    onFilterSelected={props.filterSelectedPositions}
                    onFilterUnselected={props.filterUnselectedPositions}
                    selectEntities={props.selectPositions}
                    unselectEntities={props.unselectPositions}
                    showSelectedAllIcon={true}
                    selectionList={props.positionSelection} />
            </Grid>
            </Grid>
        </>
    )
}